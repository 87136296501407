import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Databox from "./services/Databox";

const settings = window.erwSettings;

if (settings.list.length === 0) {
    settings.list = '[]';
}
Databox.storeSelected(settings.list,settings.datum);


ReactDOM.render(
    <React.StrictMode>
        <App settings={settings} />
    </React.StrictMode>,
    document.getElementById('erw-root')
);
