import {useHistory} from "react-router-dom";
import React, {useState,useEffect} from "react";
import timelineElements from "../services/timelineElements";
import moment from "moment";
import {VerticalTimeline, VerticalTimelineElement} from "react-vertical-timeline-component";
import AccessAlarm from "@material-ui/icons/AccessAlarm";
import AnnouncementOutlined from "@material-ui/icons/AnnouncementOutlined";
import NotificationImportant from "@material-ui/icons/NotificationImportant";
import {ReactComponent as WorkIcon} from "../work.svg";
import "react-vertical-timeline-component/style.min.css";
import Databox from "../services/Databox";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
//import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


function Timeline() {
    const history = useHistory();
    const [TimelineElements, setTimelineElements] = useState(timelineElements)
    let elementsx = Databox.GetSelected();
    let Eerste_ziektedag = Databox.Getdata();
    let ptss = Databox.Getptss();
    var Aantal_dagen_ziek = Databox.Getdeltadagen(Eerste_ziektedag);
const  rows = Databox.GetFields(Eerste_ziektedag);
    function infojump(element) {
        history.push("/tijdlijnziekte/info/" + element.key);
    }


    const sayHello = (element) => {
        // delete
        // setTimelineElements(TimelineElements.filter((TimelineElement) => TimelineElement.id !== element.id))
        // move to right
        element.position = "left";
        element.static = "Yes";
        let index = 1 ;
        setTimelineElements(
            TimelineElements.map(item =>
                item.key === index
                    ? {...item, element}
                    : item
            ))
        Databox.storeAll(TimelineElements);
        Databox.savetip(element.key);
    }

    useEffect(() => {
      const jump_date = Databox.GetJump(Eerste_ziektedag);
       if (jump_date !== 0) {
           document.getElementById(jump_date).scrollIntoView();
console.log(rows);
           if (rows.length > 0) {
               setOpen(true);
           }
       }
    }, []);


    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };


    return (

        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Welke acties zijn nog niet uitgevoerd
                </DialogTitle>
                <DialogContent dividers>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">actie</TableCell>
                                    <TableCell align="left">dagen geleden</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.actie}>
                                        <TableCell component="th" scope="row" align="left">{row.actie}</TableCell>
                                        <TableCell align="left">{row.dagen}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>



                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Sluiten
                    </Button>
                </DialogActions>
            </Dialog>

            <div className="header-timeline" >
                Eerste ziektedag: {Eerste_ziektedag} {
                                        ptss ? "+ PTSS" : ""
                                    }<br />
                Aantal dagen ziek: {Aantal_dagen_ziek}
            </div>
            <div className="header-timeline" >
                <span className="header-left">Voltooid</span>
                <span className="header-left">Te Voltooien</span>
            </div>

            <VerticalTimeline className="vertical-timeline-custom-line">
                {TimelineElements.map((element) => {
                    let isWorkIcon =  <WorkIcon />;
                    let isworkButton = "Level_W";
                    let iscontentStyle = {background: 'rgb(33, 150, 243)', color: '#fff' };
                    let iscontentArrowStyle={ borderRight: '7px solid  rgb(33, 150, 243)' };
                    let isiconStyle={ background: 'rgb(33, 150, 243)', color: '#fff' };
                    let Static = element.static !== "Yes";
                    let showButton =
                        element.buttonText !== undefined &&
                        element.buttonText !== null &&
                        element.buttonText !== "";
                    let actiedag = moment(Eerste_ziektedag,'DD-MM-yyyy').add(element.Deltadag, 'days').format('DD-MM-yyyy');
                    switch(element.level) {
                        case 'W':
                            isWorkIcon =  <AccessAlarm />;
                            isworkButton = "Level_W";
                            iscontentStyle = { background: '#962725', color: '#fff' };
                            iscontentArrowStyle={ borderRight: '7px solid  #962725' };
                            isiconStyle={ background: '#962725', color: '#fff' };
                            break;
                        case 'B':
                            isWorkIcon =  <NotificationImportant />;
                            isworkButton = "Level_B";
                            iscontentStyle = { background: '#E7832C', color: '#fff' };
                            iscontentArrowStyle={ borderRight: '7px solid  #E7832C' };
                            isiconStyle={ background: '#E7832C', color: '#fff' };
                            break;
                        case 'T':
                            isWorkIcon =  <AnnouncementOutlined />;
                            isworkButton = "Level_T";
                            iscontentStyle = { background: '#009640', color: '#fff' };
                            iscontentArrowStyle={ borderRight: '7px solid  #009640' };
                            isiconStyle={ background: '#009640', color: '#fff' };
                            break;
                        case 'M':
                            isWorkIcon =  <AccessAlarm />;
                            isworkButton = "Level_M";
                            iscontentStyle = { background: '#10579A', color: '#fff' };
                            iscontentArrowStyle={ borderRight: '7px solid  #10579A' };
                            isiconStyle={ background: '#10579A', color: '#fff' };
                            break;
                        default:
                            isWorkIcon =  <WorkIcon />;
                            isworkButton = "Level_M";
                            iscontentStyle = {background: 'rgb(33, 150, 243)', color: '#fff' };
                            iscontentArrowStyle={ borderRight: '7px solid  rgb(33, 150, 243)' };
                            isiconStyle={ background: 'rgb(33, 150, 243)', color: '#fff' };
                    }
                    if ( elementsx.includes(element.key)) {
                        element.position = "left";
                        element.static = "Yes";
                    };

                    /*if (Databox.loadtip(element.key)) {
                        element.position = "left";
                        element.static = "Yes";
                    };*/
                    if (Aantal_dagen_ziek > element.Deltadag) {
                        //alert( element.Deltadag);
                        //jump_date = element.jid;
                    }
                    return (
                        <VerticalTimelineElement
                            id = {element.jid.toString()}
                            key={element.key.toString()}
                            date={element.date}
                            dateClassName="date"
                            position={element.position}
                            contentStyle={iscontentStyle}
                            contentArrowStyle={iscontentArrowStyle}
                            iconStyle={isiconStyle}
                            icon={isWorkIcon}
                        >
                            <h3 className="vertical-timeline-element-title">
                                {element.title}  {showButton && (
                                <button
                                    className={`button2 ${
                                        isworkButton
                                    }`} onClick={() => {
                                    infojump(element);
                                }}
                                >
                                    {element.buttonText}
                                </button>
                            )}
                            </h3>
                            <h5 className="vertical-timeline-element-subtitle">
                                {actiedag}
                            </h5>
                            <p id="description">{element.description}</p>
                            {Static && ( <button className="button2 button_ok"
                                                 onClick={() => {
                                                     sayHello(element,element.key);
                                                 }}
                            >       gedaan
                            </button>)}
                        </VerticalTimelineElement>
                    );
                })}
            </VerticalTimeline>
        </div>
    );
}

export default Timeline;
