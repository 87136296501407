import moment from "moment";
import timelineElements from "../services/timelineElements";
import axios from "axios";


class Databox {
    GetFields(Eerste_ziektedag) {
        let aantaldagen = this.Getdeltadagen(Eerste_ziektedag);
        let jumpelement=[];
        timelineElements.forEach(element => {
            if ((aantaldagen > element.Deltadag) && (element.level === 'B') && (element.position === "right")) {
                jumpelement.unshift({"actie": element.description,"dagen": (aantaldagen - element.Deltadag)});
            }


        });
        return jumpelement;
    }

   GetJump(Eerste_ziektedag) {
       let aantaldagen = this.Getdeltadagen(Eerste_ziektedag);
       let first = 0;
       let jumpelement = 0;
       timelineElements.forEach(element => {
           if (aantaldagen > element.Deltadag) {
               first = 1;
           }
           if ((aantaldagen < element.Deltadag) && (first === 1) ){
               jumpelement = element.jid;
               first = 10;
           }


       });

       return jumpelement;
   }

   Getdeltadagen(Eerste_ziektedag){
       var m = moment(Eerste_ziektedag,'DD-MM-yyyy');  // or whatever start date you have
       var today = moment().startOf('day');
       return Math.round((today - m) / 86400000);
   }

    Getdata() {
        const mydate = localStorage.getItem('date');
        let mydateFormat = 'DD-MM-YYYY';
        const toDateFormat = moment(mydate,mydateFormat).format(mydateFormat);
        //console.log(toDateFormat);
        if (moment(toDateFormat, mydateFormat, true).isValid()) {
            return toDateFormat;
        } else {
            this.Putdate(moment().format(mydateFormat));
            return moment().format(mydateFormat);
        }
    }

    Putdate(date) {
        localStorage.setItem("date", date);
        const baseURL = "/wp-content/plugins/Tijdlijnziekte/store.php?datum=" + date;
        axios.get(baseURL).then((response) => {
            //  console.log(response.data);
        });
    }

    Putptss(ptss) {
        localStorage.setItem("ptss", ptss);
    }

    Getptss() {
        return localStorage.getItem("ptss");
    }
    loadtip(id) {
        //const mydate = localStorage.getItem('tip'+ id);
        //if (mydate === "1") {
        //    return true;
        //} else {
        //    return false;
       // }
    }

    savetip(id) {
        //localStorage.setItem("tip" + id, "1");
    }

    storeAll(AllElements){
        var ArrSelect = [];
        AllElements.map(item =>{
            if (item.position === "left") {
                ArrSelect.push(item.key);
            }
            }
        );
        //store remote
        localStorage.setItem("list", JSON.stringify(ArrSelect));




        const baseURL = "/wp-content/plugins/Tijdlijnziekte/store.php?list=" + JSON.stringify(ArrSelect);
        axios.get(baseURL).then((response) => {
          //  console.log(response.data);
        });

    }

    GetSelected() {
        var selected = [];
        selected = JSON.parse(localStorage.getItem("list"));
        if (selected === null) {
            selected = [];
        }
        return selected;
    }

    storeSelected(list,datum) {
        localStorage.setItem("list", list);
        localStorage.setItem("date", datum);
    }

    deletetips(){
        localStorage.setItem("list", JSON.stringify([]));
        //for (var myindex = 0; myindex < 20; myindex++) {
        //    localStorage.removeItem("tip" + myindex);
       // }

    }

}

export default new Databox();
