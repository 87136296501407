import React, { useState } from 'react';
import "./App.css";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Timeline from "./pages/Timeline";
import Start from "./pages/start";
import Info from "./pages/info";
import Databox from "./services/Databox";

function App({ settings }) {
   const [ptss, setptss] = useState(Databox.Getptss());

  return (
      <Router>
          <div>
              <Switch>
                  <Route exact path="/tijdlijnziekte/">
                      <Start />
                  </Route>
                  <Route path="/tijdlijnziekte/tijdlijn">
                      <Timeline />
                  </Route>
                  <Route path='/tijdlijnziekte/info/:userId' component={Info} />
              </Switch>
          </div>

      </Router>

  );
}

export default App;
