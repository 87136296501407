import {useHistory} from "react-router-dom";
import React from "react";
import timelineElements from "../services/timelineElements";

function Info(props) {
    let userId = props.match.params.userId;
    const History = useHistory();
    let item = timelineElements.find(item => item.key === parseInt(userId));
    return (
        <div className="header-timeline2" >
            <div>
                <h2>{item.buttonText}: {item.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: item.tips }} />
            </div>
           <div><br /><br />
                   <button className="button2 button_ok"
                           onClick={History.goBack}
                   >       terug
                   </button>
           </div>
        </div>
    );
}

export default Info;
