import {useHistory} from "react-router-dom";
import React from 'react';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import Checkbox from '@material-ui/core/Checkbox';
import Databox from "../services/Databox";
import MomentUtils from "@date-io/moment";
import moment from "moment";

function Start() {
    const history = useHistory();
    //const [selectedDate, setSelectedDate] = useState(moment());
     let datumnu = moment(Databox.Getdata(), 'DD-MM-YYYY');

   // console.log(selectedDate);
         //Databox.Getdata(); // moment(Databox.Getdata(),'DD-MM-YYYY');
//value={datumnu}

//    console.log( moment(Databox.Getdata()));
//    console.log(moment(Databox.Getdata(), 'YYYY-MM-DDTHH:mm:ss.SSSSZ').format('DD-MM-YYYY'));

    const handleDateChange = (date) => {
        // console.log(date);
       // setSelectedDate(moment(date, 'DD-MM-YYYY').format('DD-MM-YYYY'));
        Databox.Putdate(moment(date, 'DD-MM-YYYY').format('DD-MM-YYYY'));
        datumnu = moment(date, 'DD-MM-YYYY').format('DD-MM-YYYY');
        Databox.deletetips();
    };

    function tijdjump() {
        //   this.context.router.push('/info');
//console.log(element);
        history.push("/tijdlijnziekte/tijdlijn");
    }

    const [checkedcheckbox, setCheckedcheckbox] = React.useState(Databox.Getptss());
   //setCheckedcheckbox(false);
    const handleChangecheckbox = (event) => {
        setCheckedcheckbox(event.target.checked);
        Databox.Putptss(event.target.checked);
    };

/*
                <div>
                    <Checkbox
                        checked={checkedcheckbox}
                        onChange={handleChangecheckbox}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /> Is er PTSS geconstateerd
                </div>
 */
    return (
        <div>

            <div className="header-timeline2" >
                <h2>Eerste ziektedag</h2>
                <div>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            format="DD-MM-YYYY"
                            label=""
                            value={datumnu}
                            onChange={handleDateChange}
                        />
                    </MuiPickersUtilsProvider>
                </div>
                <div><br /><br />
                    <button className="button2 button_ok"
                            onClick={() => {
                                tijdjump();
                            }}
                    >       Ga Naar tijdlijn
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Start;
